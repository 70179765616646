import { useEffect, useState } from "react";
import { Notify } from "../../components/notify";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import LoadingComponent from "../../components/LoadingComponent";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
import userService from "../../services/Users/UserService";
import EmployeeProfile from "../../services/Users/EmployeeProfile";
import { useParams } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import BackButton from "../../components/BackButton";
import UserTransactionsBlock from "../../blocks/UserTransactionsBlock";
import metadataService from "../../services/Metadatas/MetadataService";
import User from "../../services/Users/User";

function WorkindTextField(props: any) {
  return <TextField variant="standard" size="small" {...props} />;
}

export default function EmployeeProfilePage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const { organizationId, id, userId } = useParams<string>();
  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [employeeProfile, setEmployeeProfile] = useState<EmployeeProfile | undefined>(undefined);
  const [employeeUser, setEmployeeUser] = useState<User | undefined>(undefined);
  const [originalEmail, setOriginalEmail] = useState<string>("");

  const [edit, setEdit] = useState(false);

  const [computeResetEnable, setComputeResetEnable] = useState(false);
  const [openDialogResetAllocation, setOpenDialogResetAllocation] = useState(false);

  const handleClickOpenDialogResetAllocation = () => {
    setOpenDialogResetAllocation(true);
  };

  const handleCloseDialogResetAllocation = () => {
    setOpenDialogResetAllocation(false);
  };

  const handleTransactionCreated = async () => {
    await loadProfile();
  };

  useEffect(() => {
    loadProfile();
  }, [user, id, userId]);

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }
  if (!employeeProfile) {
    return t("admin.user-notfound");
  }

  const orgId = organizationId ? parseInt(organizationId) : -1;
  const org = user?.organizations.find((o) => o.id === orgId);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <BackButton url={`/admin/employee-profiles/${organizationId}`} />
        <Typography variant="h2">{t("admin.employee-profile.title")}</Typography>
      </Stack>
      <Paper elevation={3} sx={{ padding: "2% 2%", marginBottom: "2%" }}>
        <Typography component="h1" variant="h5" marginBottom={2}>
          {t("admin.employee-profile.employee")}
        </Typography>

        <Grid container spacing={2} marginBottom={3}>
          <Grid item lg={3}>
            <WorkindTextField label={t("user.field-employeeNumber")} disabled id="employeeNumber" value={employeeProfile?.employeeNumber} />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-firstname")}
              value={employeeProfile?.firstname}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, firstname: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-lastname")}
              value={employeeProfile?.lastname}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, lastname: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-email")}
              value={employeeProfile?.email}
              required
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, email: e.target.value });
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={3}>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-companyCode")}
              value={employeeProfile?.companyCode}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, companyCode: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-division")}
              value={employeeProfile?.division}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, division: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-jobCountry")}
              value={employeeProfile?.jobCountry}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, jobCountry: e.target.value });
              }}
              fullWidth
            />
            {/* <TextField disabled={!edit} select label={t("user.field-jobCountry")} value={employeeProfile?.jobCountry} fullWidth>
              <MenuItem value={"CAN"}>Canada</MenuItem>;<MenuItem value={"USA"}>USA</MenuItem>
            </TextField> */}
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-personalLocation")}
              value={employeeProfile?.personalLocation}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, personalLocation: e.target.value });
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={3}>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-employeeTypeLong")}
              value={employeeProfile?.employeeType}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, employeeType: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <DatePicker
              disabled={!edit}
              label={t("user.field-employeeTypeDateLong")}
              value={employeeProfile?.employeeTypeDate}
              onChange={(date: any) => {
                setEmployeeProfile({ ...employeeProfile, employeeTypeDate: date });
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-status")}
              id="status"
              value={employeeProfile?.status}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, status: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <DatePicker
              disabled={!edit}
              label={t("user.field-statusDate")}
              value={employeeProfile?.statusDate}
              onChange={(date: any) => {
                setEmployeeProfile({ ...employeeProfile, statusDate: date });
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={3}>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-payType")}
              value={employeeProfile?.payType}
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, payType: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <DatePicker
              disabled={!edit}
              label={t("user.field-payTypeDate")}
              value={employeeProfile?.payTypeDate}
              onChange={(date: any) => {
                setEmployeeProfile({ ...employeeProfile, payTypeDate: date });
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-jobCode")}
              value={employeeProfile?.jobCode}
              fullWidth
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, jobCode: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <DatePicker
              disabled={!edit}
              label={t("user.field-jobCodeDate")}
              value={employeeProfile?.jobCodeDate}
              onChange={(date: any) => {
                setEmployeeProfile({ ...employeeProfile, jobCodeDate: date });
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={3}>
            <WorkindTextField
              disabled={!edit}
              label={t("user.field-payGroup")}
              value={employeeProfile?.payGroup}
              fullWidth
              onChange={(e: any) => {
                setEmployeeProfile({ ...employeeProfile, payGroup: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <DatePicker
              disabled={!edit}
              label={t("user.field-hireDate")}
              value={employeeProfile?.hireDate}
              onChange={(date: any) => {
                setEmployeeProfile({ ...employeeProfile, hireDate: date });
              }}
            />
          </Grid>

          <Grid item lg={3}>
            <WorkindTextField disabled label={t("user.field-allocation")} value={`${employeeProfile?.allocation.toFixed(2)} $`} />
          </Grid>
        </Grid>

        <Grid container spacing={2} paddingTop={2}>
          {edit && (
            <>
              <Grid item>
                <Button variant="contained" onClick={saveEmployeeProfile}>
                  {t("common.save")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={cancelEmployeeProfile}>
                  {t("common.cancel")}
                </Button>
              </Grid>
            </>
          )}
          {!edit && (
            <Grid item>
              <Button variant="contained" onClick={() => setEdit(true)}>
                {t("common.edit")}
              </Button>
            </Grid>
          )}
          {workindAdmin && (
            <>
              <Grid item>
                <Button variant="outlined" onClick={calcEmployeeProfile}>
                  {t("admin.employee-profile.label-calc")}
                </Button>
              </Grid>
              {computeResetEnable && (
                <Grid item>
                  <Button variant="outlined" onClick={handleClickOpenDialogResetAllocation}>
                    {t("admin.employee-profile.label-reset")}
                  </Button>
                  <Dialog
                    open={openDialogResetAllocation}
                    onClose={handleCloseDialogResetAllocation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{t("admin.employee-profile.label-resetAllocationLabel")}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">{t("admin.employee-profile.label-resetAllocationMsg")}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialogResetAllocation}>{t("admin.employee-profile.label-cancelBtn")}</Button>
                      <Button onClick={resetEmployeeProfile} autoFocus>
                        {t("admin.employee-profile.lable-YesBtn")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              )}

              {employeeProfile.jobCode && employeeProfile.jobCountry && (
                <Grid item>
                  <Button variant="outlined" onClick={displayJobcodeAllocation}>
                    {t("admin.employee-profile.jobcode-allocation")}
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Paper>

      {org && user && employeeUser && <UserTransactionsBlock user={employeeUser} organization={org} onTransactionCreated={handleTransactionCreated} />}
    </>
  );

  async function loadProfile() {
    try {
      if (user && organizationId && id && userId) {
        if (!workindAdmin && user.organizations.length <= 0) {
          console.log("EmployeeProfilePage.useEffet() : User has no organisations");
          setLoading(LoadingStatus.Failure);
        } else {
          setLoading(LoadingStatus.Loading);

          const orgId = parseInt(organizationId);

          const profile = await userService.getEmployeeProfile(orgId, id);
          setEmployeeProfile(profile);
          setOriginalEmail(profile?.email ?? "");

          if (profile) {
            const employeeUser = await userService.getUserProfile(orgId, profile.userId.toString());
            setEmployeeUser(employeeUser);
          }

          setLoading(user ? LoadingStatus.Success : LoadingStatus.Failure);
        }
      }
    } catch (e) {
      setLoading(LoadingStatus.Failure);
      Notify.error(t("admin.users.error-loading"));
    }
  }

  async function calcEmployeeProfile() {
    const res = await userService.computeAllocationOld(originalEmail);
    if (res === undefined) {
      Notify.error(t("admin.employee-profile.error-calc"));
    } else {
      Notify.info(t("admin.employee-profile.label-valAllocation") + ": " + res + "$");
      setComputeResetEnable(true);
    }
  }

  async function displayJobcodeAllocation() {
    if (employeeProfile && employeeProfile.jobCode && employeeProfile.jobCountry) {
      const res = await metadataService.getJobCode(employeeProfile.jobCode, employeeProfile.jobCountry);
      if (res === undefined) {
        Notify.error(t("admin.employee-profile.error-getmetadata"));
      } else {
        Notify.info("Paygrade: " + res.paygrade + ": " + res.allocation + "$");
      }
    }
  }

  async function resetEmployeeProfile() {
    const res = await userService.resetAllocation(originalEmail);
    if (res === undefined) {
      Notify.error(t("admin.employee-profile.error-reset"));
    } else {
      Notify.info(t("admin.employee-profile.label-valBalance") + ": " + res + "$");
      loadProfile();
      setComputeResetEnable(false);
      setOpenDialogResetAllocation(false);
    }
  }

  async function saveEmployeeProfile() {
    if (!employeeProfile) return;

    if (!employeeProfile.email || employeeProfile.email.length === 0) {
      Notify.error(t("admin.employee-profile.email-required"));
      return;
    }
    if (!employeeProfile.firstname || employeeProfile.firstname.length === 0) {
      Notify.error(t("admin.employee-profile.firstname-required"));
      return;
    }
    if (!employeeProfile.lastname || employeeProfile.lastname.length === 0) {
      Notify.error(t("admin.employee-profile.lastname-required"));
      return;
    }
    if (!employeeProfile.jobCountry || employeeProfile.jobCountry.length === 0) {
      Notify.error(t("admin.employee-profile.jobCountry-required"));
      return;
    }

    try {
      setLoading(LoadingStatus.Loading);
      setEdit(false);

      const res = await userService.updateEmployeeProfile(originalEmail, employeeProfile);
      if (res) {
        if (res.message) {
          Notify.error(res.message);
        }
        const fields = res.details?.fields;
        if (fields) {
          var keys = Array.from(fields.keys());
          for (let key of keys) {
            Notify.error(`${key}: ${fields.get(key)}`);
          }
        }
      } else {
        Notify.success(t("admin.employee-profile.success-updating"));
      }
    } finally {
      setLoading(LoadingStatus.Success);
    }

    await loadProfile();
  }

  async function cancelEmployeeProfile() {
    setEdit(false);
    await loadProfile();
  }
}
