import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PageSection from "../components/PageSection";
import FormatHelpers from "../services/FormatHelpers";
import i18n from "../services/i18n";
import Activity from "../services/Activities/Activity";

interface Props {
  activities: Array<Activity>;
}

export default function ActivitiesBlock(props: Props) {
  const { t } = useTranslation();

  return (
    <PageSection title={t("common.activities")}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("activities.created-on")}</TableCell>
              <TableCell>{t("activities.created-by")}</TableCell>
              <TableCell>{t("activities.activity-type")}</TableCell>
              <TableCell>{t("activities.description")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.activities.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ minWidth: 145 }}>{FormatHelpers.formatDateTimeShort(row.createdOn)}</TableCell>
                <TableCell sx={{ minWidth: 145 }}>{row.createdByDesc}</TableCell>
                <TableCell sx={{ minWidth: 145 }}>{row.activityType}</TableCell>
                <TableCell>{row.description[i18n.language]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageSection>
  );
}
