import { useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { useSessionContext } from "../../contexts/SessionContext";
import i18n from "../../services/i18n";
import OrganizationFeature from "../../services/Organizations/OrganizationFeature";

export default function WalletsComponent() {
  const { t } = useTranslation();
  const { user, organizationHasFeature } = useSessionContext();

  const userOrgId = user?.user.organizationId;
  const userOrg = user?.organizations.find((o) => o.id === userOrgId);

  const useWallet = userOrgId && organizationHasFeature(userOrgId, OrganizationFeature.UseAccountWallet);
  if (!useWallet) return <></>;

  return (
    <Box mb={8} mt={-8}>
      <Typography variant="h3" mb={1.75} sx={{ display: { xs: "none", md: "block" } }}>
        {t("dashboard.userPage.wallets-label")}
      </Typography>

      {userOrg?.wallets.map((wallet) => {
        return (
          <Box mt={2}>
            <Link component={RouterLink} to={`/wallets/${wallet.id}`} underline="none">
              {wallet.display[i18n.language]}
            </Link>
          </Box>
        );
      })}
    </Box>
  );
}
