import { DataGrid, GridColDef, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../../components/LoadingComponent";
import { useSessionContext } from "../../contexts/SessionContext";
import { useNavigate } from "react-router-dom";

export default function OrganizationsPage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          navigate(`/admin/organizations/${row?.id}`);
        };

        return (
          <Button variant="contained" color="primary" size="small" onClick={onClick}>
            {t("common.detail")}
          </Button>
        );
      },
    },
    { field: "id", headerName: "Id", type: "string", minWidth: 70 },
    {
      field: "name",
      flex: 1,
      headerName: t("admin.organizations.name"),
      type: "string",
      minWidth: 200,
    },
  ];

  if (!user) {
    return <LoadingComponent />;
  }

  function CustomToolbar() {
    const exportFields: string[] = ["id", "name"];

    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridCsvExportMenuItem options={{ fileName: "organizations.csv", fields: exportFields, utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h4">{t("admin.organizations.title")}</Typography>{" "}
      </Stack>

      <DataGrid
        rows={user.organizations}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[25]}
        localeText={{
          toolbarQuickFilterPlaceholder: t("common.search"),
          toolbarExport: t("common.export"),
          toolbarExportCSV: t("common.datagrid-downloadcsv"),
          toolbarExportPrint: t("common.datagrid-print"),
          noRowsLabel: t("common.datagrid-norows"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => t("common.datagrid-pagination-count", { from, to, count }),
          },
        }}
      />
    </>
  );
}
