import { useEffect, useState } from "react";
import { Notify } from "../../components/notify";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import LoadingComponent from "../../components/LoadingComponent";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
import FormatHelpers from "../../services/FormatHelpers";
import OrganizationSelector from "../../components/OrganizationSelector";
import userService from "../../services/Users/UserService";
import User from "../../services/Users/User";
import Unauthorized from "../../components/Unauthorized";
import UserStatusSelector from "../../components/UserStatusSelector";
import { useNavigate, useParams } from "react-router-dom";
import Organization from "../../services/Organizations/Organization";
import OrganizationHelpers from "../../services/Organizations/OrganizationHelpers";
import Field from "../../services/DynamicEntity/Field";
import i18n from "../../services/i18n";
import OrganizationFeature from "../../services/Organizations/OrganizationFeature";

export default function UsersPage() {
  const { t } = useTranslation();
  const { user, organizationHasFeature } = useSessionContext();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.None);
  const [users, setUsers] = useState<Array<User>>([]);
  const [organizationSelected, setOrganizationSelected] = useState<Organization | undefined>(undefined);
  const [statusSelected, setStatusSelected] = useState("Actif");

  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel>({
    balance_raw: false,
    referenceId: false,
  });

  useEffect(() => {
    if (organizationId !== null && organizationId !== undefined) {
      const orgId = parseInt(organizationId);
      let org = user?.organizations.find((x) => x.id === orgId);
      if (org !== null) {
        setOrganizationSelected(org);
      }
    }
    if (user && !workindAdmin && user.organizations.length > 0) {
      setOrganizationSelected(user.organizations[0]);
    }
  }, [user, organizationId]);

  useEffect(() => {
    async function get() {
      try {
        if (organizationSelected) {
          setLoading(LoadingStatus.Loading);
          const list = await userService.getUsersOfOrganization(organizationSelected.id);
          setUsers(() => {
            if (statusSelected === "Actif") {
              return list.filter((key) => key.enabled === 1);
            } else if (statusSelected === "Inactif") {
              return list.filter((key) => key.enabled === 0);
            } else {
              return list;
            }
          });

          setVisibleColumns({
            ...visibleColumns,
            referenceId: organizationSelected?.useReferenceIdHasExternalKey ?? false,
          });

          setLoading(LoadingStatus.Success);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("admin.users.error-loading"));
      }
    }
    get();
  }, [organizationSelected, statusSelected]);

  function onOrganizationChange(orgId: number) {
    const org = user?.organizations.find((o) => o.id === orgId);
    setOrganizationSelected(org);
  }

  const useWallet = organizationSelected && organizationHasFeature(organizationSelected.id, OrganizationFeature.UseAccountWallet);
  const userTable = organizationSelected ? OrganizationHelpers.getOrganizationUserTable(organizationSelected) : undefined;
  const userTableFields = userTable ? userTable.fields : new Map<string, Field>();

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          navigate(`/admin/users/${organizationSelected?.id}/${row.id}`);
        };

        return (
          <Button variant="contained" color="primary" size="small" onClick={onClick}>
            {t("common.detail")}
          </Button>
        );
      },
    },
    { field: "id", headerName: "Id", type: "string", minWidth: 70 },
    { field: "referenceId", headerName: t("user.field-referenceid"), type: "string", minWidth: 70 },
    {
      field: "name",
      flex: 1,
      headerName: t("common.user"),
      type: "string",
      minWidth: 170,
      valueGetter: ({ row }) => {
        return row.firstname + (row.firstname ? " " + row.lastname : "");
      },
    },
    {
      field: "enabled",
      headerName: t("user.field-status"),
      type: "string",
      minWidth: 80,
      valueGetter: ({ row }) => {
        return row.enabled ? t("user.field-actif") : t("user.field-inactif");
      },
    },
    { field: "email", headerName: t("common.email"), type: "string", minWidth: 280 },
  ];

  const exportFields: string[] = ["id", "referenceId", "name", "email", "enabled"];

  /* Create wallet allocation and balance columns */
  if (useWallet) {
    for (let wallet of organizationSelected.wallets) {
      //allocation
      const fieldAllocation = `${wallet.id}-allocation`;
      columns.push({
        field: fieldAllocation,
        headerName: `${wallet.display[i18n.language]} - ${t("admin.users.allocation")}`,
        type: "number",
        valueGetter: (params) => {
          const userWallet = params.row.wallets.find((w: any) => w.organizationWalletId === wallet.id);
          //console.log(wallet.id, params.row.wallets, userWallet);
          const value = userWallet ? userWallet.allocation : 0;
          return FormatHelpers.formatMoney(value);
        },
      });

      //balance
      const fieldBalance = `${wallet.id}-balance`;
      columns.push({
        field: fieldBalance,
        headerName: `${wallet.display[i18n.language]} - ${t("admin.users.balance")}`,
        type: "number",
        valueGetter: (params) => {
          const userWallet = params.row.wallets.find((w: any) => w.organizationWalletId === wallet.id);
          //console.log(wallet.id, params.row.wallets, userWallet);
          const value = userWallet ? userWallet.allocation : 0;
          return FormatHelpers.formatMoney(value);
        },
      });

      exportFields.push(fieldAllocation);
      exportFields.push(fieldBalance);
    }
  } else {
    columns.push({
      field: "balance",
      headerName: t("admin.users.balance"),
      type: "number",
      minWidth: 140,
      disableExport: true,
      valueFormatter: ({ value }) => {
        return FormatHelpers.formatMoney(value);
      },
    });
    columns.push({
      field: "balance_raw",
      headerName: t("admin.users.balance"),
      type: "number",
      flex: 2,
      valueGetter: ({ row }) => {
        return row.balance;
      },
    });

    exportFields.push("balance_raw");
  }

  /* Create User Attributes columns */
  if (userTable && userTableFields) {
    const fieldKeys = Array.from(userTable.fields.keys());

    for (var fieldId of fieldKeys) {
      const field = userTable.fields.get(fieldId);
      if (!field) continue;

      columns.push({
        field: field.id,
        headerName: field.display[i18n.language],
        type: "string",
        valueGetter: (params) => {
          const attrsMap = new Map(Object.entries(params.row.attributes));
          //console.log(field.id, attrsMap.get(field.id));
          return attrsMap.get(field.id) ?? "";
        },
      });
      exportFields.push(field.id);
    }
  }

  const orgAdmin = user && RolesHelpers.IsOrganizationAdminFromList(user.roles);
  if (!orgAdmin) {
    return <Unauthorized />;
  }
  if (!user || loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  function CustomToolbar() {
    let fileName = organizationSelected ? organizationSelected.name : "users";

    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <UserStatusSelector value={statusSelected} onChange={setStatusSelected} />
        <GridCsvExportMenuItem options={{ fileName: fileName, fields: exportFields, utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  const totalBalance = users.length === 0 ? 0 : users.map((item) => item.balance).reduce((prev, next) => prev + next);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h4">{t("admin.users.title")}</Typography>{" "}
      </Stack>
      {workindAdmin && (
        <Stack direction="row" alignItems="center" spacing={1} mb={3}>
          {workindAdmin && <OrganizationSelector allowAllSelection={false} onChange={onOrganizationChange} value={organizationSelected?.id ?? -1} />}
        </Stack>
      )}
      <Stack direction="row" alignItems="center" spacing={3} mb={3}>
        <Typography color="inherit" noWrap>
          {t("admin.users.total-balance")}:
        </Typography>{" "}
        <Typography color="inherit" sx={{ fontWeight: "bold" }}>
          {FormatHelpers.formatMoney(totalBalance)}
        </Typography>
      </Stack>

      <DataGrid
        rows={users}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          columns: {
            columnVisibilityModel: visibleColumns,
          },
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[25]}
        localeText={{
          toolbarQuickFilterPlaceholder: t("common.search"),
          toolbarExport: t("common.export"),
          toolbarExportCSV: t("common.datagrid-downloadcsv"),
          toolbarExportPrint: t("common.datagrid-print"),
          noRowsLabel: t("common.datagrid-norows"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => t("common.datagrid-pagination-count", { from, to, count }),
          },
        }}
      />
    </>
  );
}
