import { Box, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import cashIcon from "../../assets/images/cash.svg";
import UserServiceDashboard from "../../services/Users/UserDashboardProfile";
import FormatHelpers from "../../services/FormatHelpers";
import InfoIcon from "../../components/InfoIcon";
import { useSessionContext } from "../../contexts/SessionContext";
import OrganizationFeature from "../../services/Organizations/OrganizationFeature";

type Props = {
  state: UserServiceDashboard | undefined;
};
export default function AllocationComponent(props: Props) {
  const { t } = useTranslation();
  const lang = localStorage.getItem("language") || "en";
  const { user, organizationHasFeature } = useSessionContext();

  let allocationTips = undefined;
  if (props.state && props.state.organization.allocationTips)
    allocationTips = lang === "fr" ? props.state.organization.allocationTips.fr : props.state.organization.allocationTips.en;
  allocationTips = allocationTips ? allocationTips.trim() : undefined;

  const useWallet = user?.user.organizationId && organizationHasFeature(user?.user.organizationId, OrganizationFeature.UseAccountWallet);

  let totalBalance = useWallet ? 0 : props.state?.balance ?? 0;
  let totalAllocation = 0;

  if (useWallet) {
    for (const wallet of user.user.wallets) {
      totalAllocation += wallet.allocation;
      totalBalance += wallet.balance;
    }
  }

  return (
    <>
      <Grid container mt={9.5} mb={10} justifyContent="space-between">
        <Grid item lg={8} md={7} xs={12}>
          <Typography variant="h1">{`${t("dashboard.userPage.hello")} ${props.state?.firstName},`}</Typography>
          <Typography variant="h2" mb={4}>
            {t("dashboard.userPage.ask-label")}
          </Typography>
        </Grid>

        <Grid item lg={4} md={5} sm={12} sx={{ maxWidth: { lg: "375px", md: "370px", xs: "100%" }, display: { md: "initial", xs: "none" } }}>
          <Paper elevation={0} sx={{ padding: "25px 34px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: 1,
                  letterSpacing: "-0.96px",
                  color: "#02334A",
                  whiteSpace: "nowrap",
                }}
              >
                {t("dashboard.userPage.amount-available")}
              </Typography>

              {allocationTips && (
                <Tooltip arrow title={<Box sx={{ padding: "15px", fontSize: "14px" }}>{parse(allocationTips)}</Box>}>
                  <div>
                    <InfoIcon />
                  </div>
                </Tooltip>
              )}

              {useWallet && (
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: 1,
                    letterSpacing: "-0.96px",
                    color: "#02334A66",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"/ "}
                  {FormatHelpers.formatMoneyLocale(totalAllocation, lang)}
                </Typography>
              )}
            </Box>

            <Stack direction="row" alignItems="middle" mt="10px">
              <img src={cashIcon} alt="Cash icon" style={{ marginTop: "5px" }} />
              <Box sx={{ display: "flex", marginLeft: "20px" }}>
                <Typography
                  sx={{
                    fontSize: "32px",
                    fontWeight: "500",
                    color: "#02334a",
                    letterSpacing: "-0.5px",
                    minWidth: "90px",
                    marginRight: "20px",
                  }}
                >
                  {FormatHelpers.formatMoneyLocale(totalBalance, lang)}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
