import { useParams } from "react-router-dom";
import userService from "../../services/Users/UserService";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme/main-theme";
import { Box, Container } from "@mui/system";
import { Button, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import WalletLink from "../../services/Organizations/WalletLink";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import organizationService from "../../services/Organizations/OrganizationService";
import { useSessionContext } from "../../contexts/SessionContext";
import { Notify } from "../../components/notify";
import i18n from "../../services/i18n";
import { NavigationType } from "../../services/Organizations/NavigationType";

export default function WalletPage() {
  const { t } = useTranslation();
  const { walletId } = useParams<string>();
  const { user } = useSessionContext();

  const reimbursementUrl = `${userService.appPortalUrl}/cascade-refunds?orgWalletID=${walletId}`;

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [links, setLinks] = useState<WalletLink[]>([]);

  useEffect(() => {
    async function get() {
      try {
        if (user && walletId) {
          setLoading(LoadingStatus.Loading);

          const links = await userService.getWalletLinks(walletId);
          setLinks(links);

          setLoading(LoadingStatus.Success);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("walletPage.error-loading"));
      }
    }

    get();
  }, [t, user]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ marginTop: 3 }}>
        <Typography variant="h3" mb={1.75} sx={{ display: { xs: "none", md: "block" } }}>
          PORTEFEUILLE {walletId}
        </Typography>

        <Box>
          <Button href={reimbursementUrl} target="_parent">
            REMBOURSEMENT
          </Button>

          <Typography mt={4} variant="h2">
            Links
          </Typography>
          {links.map((link) => {
            var target = link.navigationType === NavigationType.External ? "_blank" : link.navigationType === NavigationType.Parent ? "_parent" : "";

            return (
              <Box mt={3}>
                <Link key={link.navigationUrl[i18n.language]} target={target} component={RouterLink} to={link.navigationUrl[i18n.language]} underline="none">
                  {link.display[i18n.language]}
                </Link>
              </Box>
            );
          })}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
