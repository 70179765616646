import { useEffect, useState } from "react";
import { Notify } from "../components/notify";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../contexts/LoadingStatus";
import LoadingComponent from "../components/LoadingComponent";
import { Box, Tab } from "@mui/material";
import transactionService from "../services/Transactions/TransactionService";
import TransactionWithAccount from "../services/Transactions/TransactionWithAccount";
import PageSection from "../components/PageSection";
import User from "../services/Users/User";
import Organization from "../services/Organizations/Organization";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import i18n from "../services/i18n";
import UserTransactionsBlockWallet from "./UserTransactionsBlockWallet";

interface Props {
  user: User;
  organization: Organization;
  onTransactionCreated: () => void;
}

export default function UserTransactionsBlock(props: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [transactions, setTransactions] = useState<Array<TransactionWithAccount>>([]);

  const [selectedTab, setSelectedTab] = useState<string>("1");

  useEffect(() => {
    load();
  }, [props.organization, props.user]);

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  const onTabSelected = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const hasWallet = props.organization.wallets.length > 0 && props.organization.wallets[0].id !== "";

  return (
    <PageSection title={t("user.wallets")}>
      <Box sx={{ width: "100%" }}>
        {hasWallet ? (
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={onTabSelected}>
                {props.organization.wallets.map((wallet) => {
                  return <Tab key={wallet.id} label={wallet.display[i18n.language]} value={wallet.id} />;
                })}
              </TabList>
            </Box>

            {props.organization.wallets.map((wallet) => {
              const walletTransactions = transactions.filter((t) => t.organizationWalletId === wallet.id);

              return (
                <TabPanel key={wallet.id} value={wallet.id}>
                  <UserTransactionsBlockWallet
                    user={props.user}
                    organizationId={props.organization.id}
                    organizationWalletId={wallet.id}
                    transactions={walletTransactions}
                    onTransactionCreated={props.onTransactionCreated}
                  />
                </TabPanel>
              );
            })}
          </TabContext>
        ) : (
          <UserTransactionsBlockWallet
            user={props.user}
            organizationId={props.organization.id}
            organizationWalletId=""
            transactions={transactions}
            onTransactionCreated={props.onTransactionCreated}
          />
        )}
      </Box>
    </PageSection>
  );

  async function load() {
    try {
      setLoading(LoadingStatus.Loading);

      const userTransactions = await transactionService.getTransactions(props.user.organizationId, props.user.id);
      setTransactions(userTransactions);

      setLoading(LoadingStatus.Success);
      console.log(`UserTransactionsBlock.load(userId=${props.user.id}, transactions=${userTransactions.length})`);

      if (props.organization.wallets.length > 0) {
        setSelectedTab(props.organization.wallets[0].id);
      }
    } catch (e) {
      setLoading(LoadingStatus.Failure);
      Notify.error(t("admin.users.error-loading"));
    }
  }
}
