import { Box, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tab, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import { useParams } from "react-router-dom";
import { useSessionContext } from "../../contexts/SessionContext";
import { t } from "i18next";
import Organization from "../../services/Organizations/Organization";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EntityTable from "../../services/DynamicEntity/EntityTable";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import i18n from "../../services/i18n";
import { FieldType } from "../../services/DynamicEntity/FieldType";
import EntityRecord from "../../services/DynamicEntity/EntityRecord";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import LoadingComponent from "../../components/LoadingComponent";
import { Notify } from "../../components/notify";
import entityService from "../../services/DynamicEntity/EntityService";
import FormatHelpers from "../../services/FormatHelpers";

function WorkindTextField(props: any) {
  return <TextField variant="standard" size="small" {...props} />;
}

export default function OrganizationPage() {
  const { user } = useSessionContext();
  const { organizationId } = useParams<string>();

  const [organization, setOrganization] = useState<Organization | undefined>(undefined);

  const [selectedTab, setSelectedTab] = useState<string>("1");
  const onTabSelected = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (organizationId) {
      const orgId = parseInt(organizationId);
      const org = user?.organizations.find((o) => o.id === orgId);
      setOrganization(org);
    }
  }, [organizationId, user]);

  if (!organization) {
    return t("admin.user-notfound");
  }

  console.log(organization);

  return (
    <>
      <PageHeader returnUrl={`/admin/organizations`} title={organization.name} />

      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={onTabSelected}>
            <Tab label={t("common.info")} value="1" />
            <Tab label={t("admin.organization.tables")} value="2" />
          </TabList>
        </Box>

        {/* Informations */}
        <TabPanel value="1">
          <PageSection>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item lg={3}>
                <WorkindTextField label={t("admin.organization.id")} disabled value={organization.id} fullWidth />
              </Grid>
              <Grid item lg={3}>
                <WorkindTextField label={t("admin.organization.name")} disabled value={organization.name} fullWidth />
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item lg={3}>
                <WorkindTextField label={t("admin.organization.accountId")} disabled value={organization.accountId} fullWidth />
              </Grid>
              <Grid item lg={3}>
                <WorkindTextField
                  label={t("admin.organization.useReferenceIdHasExternalKey")}
                  disabled
                  value={organization.useReferenceIdHasExternalKey}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item lg={6}>
                <WorkindTextField label={t("admin.organization.features")} disabled value={organization.features} fullWidth />
              </Grid>
            </Grid>
          </PageSection>
        </TabPanel>

        {/* Tables */}
        <TabPanel value="2">
          <PageSection>
            <OrganizationTables organization={organization} />
          </PageSection>
        </TabPanel>
      </TabContext>
    </>
  );
}

function OrganizationTables({ organization }: { organization: Organization }) {
  const tables = organization.tables;

  const [tableId, setTableId] = useState<string>("");

  const handleTableSelectionChange = (event: SelectChangeEvent) => {
    setTableId(event.target.value as string);
  };

  const selectedTable = tables.find((t) => t.id === tableId);

  return (
    <>
      <Stack direction="row" alignItems="center" mb={2}>
        <InputLabel id="table" sx={{ marginRight: 2 }}>
          {t("admin.organization.tables")}
        </InputLabel>
        <Select labelId="table" value={tableId} onChange={handleTableSelectionChange} sx={{ minWidth: 300 }}>
          <MenuItem value=""></MenuItem>
          {tables
            .filter((t) => t.id !== "UserData")
            .map((table) => {
              return (
                <MenuItem key={table.id} value={table.id}>
                  {table.id}
                </MenuItem>
              );
            })}
        </Select>
      </Stack>

      {selectedTable && <OrganizationTable organizationId={organization.id} table={selectedTable} />}
    </>
  );
}

function OrganizationTable({ organizationId, table }: { organizationId: number; table: EntityTable }) {
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.None);
  const [records, setRecords] = useState<EntityRecord[]>([]);

  useEffect(() => {
    async function get() {
      try {
        setLoading(LoadingStatus.Loading);

        const list = await entityService.getTableRecords(organizationId, table.id);
        setRecords(list);

        setLoading(LoadingStatus.Success);
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("admin.users.error-loading"));
      }
    }
    get();
  }, [table]);

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  const fieldKeys = Array.from(table.fields.keys());

  const columns: GridColDef[] = [{ field: "id", headerName: "Id", type: "string", minWidth: 70 }];

  const columnsToExport: string[] = ["Id"];
  for (const fieldId of fieldKeys) {
    const field = table.fields.get(fieldId);
    if (field) {
      columns.push({
        field: fieldId,
        headerName: field.display[i18n.language],
        type: field.type === FieldType.Number ? "number" : "string",
        minWidth: 110,
        valueGetter: (params) => {
          let value = params.row.values[params.field];
          console.log(value, field, params);

          if (!value) {
            return "";
          }

          if (field.type === FieldType.Datetime) value = FormatHelpers.formatDateShort(new Date(value));

          return value;
        },
      });

      columnsToExport.push(fieldId);
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridCsvExportMenuItem options={{ fileName: `${table.id}.csv`, fields: columnsToExport, utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  //console.log(records);
  return (
    <>
      <DataGrid
        rows={records}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[25]}
        localeText={{
          toolbarQuickFilterPlaceholder: t("common.search"),
          toolbarExport: t("common.export"),
          toolbarExportCSV: t("common.datagrid-downloadcsv"),
          toolbarExportPrint: t("common.datagrid-print"),
          noRowsLabel: t("common.datagrid-norows"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => t("common.datagrid-pagination-count", { from, to, count }),
          },
        }}
      />
    </>
  );
}
