import WorkindApiClient from "../WorkindApiClient";
import EntityRecord from "./EntityRecord";

class EntityService extends WorkindApiClient {
  async getTableRecords(organizationId: number, tableId: string): Promise<Array<EntityRecord>> {
    const list = await this.get<Array<EntityRecord>>(`${this.apiUrl}/organizations/${organizationId}/tables/${tableId}/records`);
    if (!list) return [];
    return list;
  }
}

const entityService = new EntityService();
export default entityService;
